import { onDomLoad, onChange, setWithSelector, formatMoney } from "../utils";
import {
  getAddons,
  remotePriceAccessor,
  addAddonShopifyCart,
  getShopifyCart,
} from "../store/actions";
import { Store } from "../store/Store";
import { Product } from "../interfaces/Product";
import { renderCIBalanceBanner } from "./companyIssues";
import { ICartLineItem } from "shopify-api-node";
import { config } from "../config";


const store = Store.getInstance();


if (store.currentPage.template === "product") {
  onDomLoad(async () => {
    const container = document.querySelector(".single-product-page");
    if (!container) {
      console.error("Product container element not found.");
      return;
    }

    const tag = store.client?.tag
    const isBart = tag == "BF" || tag == "BS" ? true : false;
    // @ts-ignore //there is no product.type in shopify IProduct type
    const productType = store.currentPage.product.type.toLowerCase();
    const productVendor = store.currentPage.product.vendor
 
    if (productVendor == "M140" &&productType == "hats") {
      const parent = document.querySelector(
        ".shopify-variation-add-to-cart.variations_button.shopify-variation-add-to-cart-disabled"
      );
      const warningDiv = document.createElement("div");
      warningDiv.classList.add("custom-hat-warning-div");
      const warningPara = document.createElement("p");
      warningPara.classList.add("custom-hat-warning-p");
      warningPara.innerHTML =
        '<span data-preserver-spaces="true">At this time,<strong> Midway Hat Co. production time</strong>  is approximately <strong>five months </strong>from date of order. </span>';
      warningDiv.appendChild(warningPara);
      parent?.insertBefore(warningDiv, parent.firstChild);
    }



    const [getVariantPrice, , products] = await remotePriceAccessor();

    const productId = Number(container.getAttribute("id")?.split("-")[1]);
    const handler = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const variantId = Number(urlParams.get("variant"));
      const variantPrice = getVariantPrice(
        variantId ? variantId : (products.get(productId)?.variants[0]?.id as number)
      );
      setWithSelector(`#product-${productId} .amount .money`, formatMoney(variantPrice));
      const cartDiv = document.querySelectorAll(
        ".woodmart-shopping-cart"
      ) as NodeListOf<HTMLDivElement>;
      const addonStatus = sessionStorage.getItem("addonStatus");
      let toolTipMsg = "";
      if (addonStatus == "added") {
        cartDiv.forEach((element) => {
          element.classList.add("display-widget");
        });
        sessionStorage.removeItem("addonStatus");
        setTimeout(() => {
          cartDiv.forEach((element) => {
            element.classList.remove("display-widget");
          });
        }, 4000);
      }
    };

    handler();
    onChange(container, handler);

    //variables

    let defaultVariant = true;
    let defaultVariant2 = true;

    const productOptions = store.currentPage.product.options; //product options
    const productvariants = store.currentPage.product.variants; //productvariants
    // @ts-ignore //there is no product.type in shopify IProduct type
    // const productType = store.currentPage.product.type.toLowerCase();
    const form = document.getElementById("product-main-form") as HTMLFormElement;
    form.action = "";
    form.method = "";
    const oldAddToCartBtn = form.querySelector(".single_add_to_cart_button") as HTMLButtonElement;
    oldAddToCartBtn.style.display = "none";
    //remove  product variants
    const oldProductVariants = document.getElementById("product-variants") as HTMLDivElement;
    oldProductVariants.style.display = "none";
    //sku span element
    const sku = document.querySelector(".sku") as HTMLSpanElement;
    const buttonDiv = form.querySelector(".shopify-variation-add-to-cart") as HTMLDivElement;
    let refChildbuttonDiv = buttonDiv.children[1];
    if (productType == "hats") {
      refChildbuttonDiv = buttonDiv.children[2];
    }
    const newAddtoCartBtn = document.createElement("button");
    newAddtoCartBtn.classList.add("custom_addtocart_button_for_addon");
    newAddtoCartBtn.textContent = "Add To Cart";
    buttonDiv.insertBefore(newAddtoCartBtn, refChildbuttonDiv);
    const customTooltipVariant1 = document.createElement("span");
    customTooltipVariant1.id = "custom_tooltip_variant1";
    customTooltipVariant1.setAttribute("data-text", `Please select a ${productOptions[0]} `);
    const customTooltipVariant2 = document.createElement("span");
    customTooltipVariant2.id = "custom_tooltip_variant2";
    customTooltipVariant2.setAttribute("data-text", `Please select a ${productOptions[1]} `);
    const cartDiv = document.querySelectorAll(
      ".woodmart-shopping-cart"
    ) as NodeListOf<HTMLDivElement>;
    const addonStatus = sessionStorage.getItem("addonStatus");
    let toolTipMsg = "";
    if (addonStatus == "added") {
      cartDiv.forEach((element) => {
        element.classList.add("display-widget");
      });
      sessionStorage.removeItem("addonStatus");
      setTimeout(() => {
        cartDiv.forEach((element) => {
          element.classList.remove("display-widget");
        });
      }, 4000);
    }
    if(productvariants.length > 1) {

    //new dropdown div

    const dropdownDivElement = document.createElement("div");
    dropdownDivElement.id = "dropdown-div-element";
    dropdownDivElement.style.marginBottom = "15px";
    dropdownDivElement.classList.add("custom_dropdown_div_element");
    form.insertBefore(dropdownDivElement, form.firstChild);

    //dropdown starts here
    if (productOptions.length == 1) {
      let labelOption1 = document.createElement("label");
      labelOption1.classList.add("custom_dropdown_label");
      labelOption1.textContent = productOptions[0] as unknown as string;
      dropdownDivElement.appendChild(labelOption1);
      // Append the <span> element after the <label> element
      if (labelOption1.parentNode) {
        labelOption1.parentNode.insertBefore(customTooltipVariant1, labelOption1.nextSibling);
      }

      let select = document.createElement("select");
      select.classList.add("custom_dropdown_select");
      select.id = "dropdown";

      // Add an empty default option
      let defaultOption = document.createElement("option");
      defaultOption.value = "";
      defaultOption.text = `Select ${productOptions[0]}`;
      select.appendChild(defaultOption);

      for (let i = 0; i < productvariants.length; i++) {
        let option = document.createElement("option");
        option.value = productvariants[i].id as unknown as string;
        option.text = productvariants[i].option1 as unknown as string;
        select.appendChild(option);
      }

      dropdownDivElement.appendChild(select);

      // Add an event listener to the select element
      select.addEventListener("change", function () {
        let selectedValue = select.value;

        if (selectedValue === "") {
          console.log("No option selected");
          return;
        }

        let selectedId = parseInt(selectedValue);
        let selectedObject = productvariants.find((item) => item.id === selectedId);

        if (selectedObject) {
          console.log("Selected Object:", selectedObject);
          sku.textContent = selectedObject.sku;
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set("variant", `${selectedObject.id}`);
          window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
          handler();
          defaultVariant = false;
          // You can do whatever you want with the selectedObject here
        }
      });
    }
    if (productOptions.length == 2) {
      // Create a label for the first dropdown
      let labelOption1 = document.createElement("label");
      labelOption1.textContent = productOptions[0] as unknown as string;
      labelOption1.classList.add("custom_dropdown_label");
      dropdownDivElement.appendChild(labelOption1);
      // Append the <span> element after the <label> element
      if (labelOption1.parentNode) {
        labelOption1.parentNode.insertBefore(customTooltipVariant1, labelOption1.nextSibling);
      }

      let selectOption1 = document.createElement("select");
      selectOption1.classList.add("custom_dropdown_select");
      selectOption1.id = "option1Dropdown";

      // Set default option texts for both dropdowns
      let defaultOptionTexts = {
        option1: `Select ${productOptions[0]}`,
        option2: `Select ${productOptions[1]}`,
      };

      // Add empty default productOptions for the first dropdown
      let defaultOption1 = document.createElement("option");
      defaultOption1.value = "";
      defaultOption1.text = defaultOptionTexts.option1;
      selectOption1.appendChild(defaultOption1);

      // Populate the first dropdown with unique option1 values
      //@ts-ignore
      let uniqueOption1Values = [...new Set(productvariants.map((item) => item.option1))];
      uniqueOption1Values.forEach((option1Value) => {
        let option = document.createElement("option");
        option.value = option1Value as unknown as string;
        option.text = option1Value as unknown as string;
        selectOption1.appendChild(option);
      });

      dropdownDivElement.appendChild(selectOption1);

      // Create a label for the second dropdown
      let labelOption2 = document.createElement("label");
      labelOption2.textContent = productOptions[1] as unknown as string;
      labelOption2.classList.add("custom_dropdown_label");
      dropdownDivElement.appendChild(labelOption2);

      if (labelOption2.parentNode) {
        labelOption2.parentNode.insertBefore(customTooltipVariant2, labelOption2.nextSibling);
      }

      let selectOption2 = document.createElement("select");
      selectOption2.classList.add("custom_dropdown_select");
      selectOption2.style.marginBottom = "10px";
      selectOption2.id = "option2Dropdown";

      // Set the initial default text for the second dropdown
      let defaultOption2 = document.createElement("option");
      defaultOption2.value = "";
      defaultOption2.text = defaultOptionTexts.option2;
      selectOption2.appendChild(defaultOption2);

      dropdownDivElement.appendChild(selectOption2);

      // Add event listener for the first dropdown
      selectOption1.addEventListener("change", function () {
        let selectedOption1 = selectOption1.value;
        // Update the default text for the second dropdown based on the selected option1
        defaultOption2.text = defaultOptionTexts.option2;
        defaultVariant2 = false;
        // Clear and reset the second dropdown
        selectOption2.innerHTML = "";
        selectOption2.appendChild(defaultOption2);

        // Populate the second dropdown with option2 values matching the selected option1
        let option2Values = productvariants
          .filter((item) => item.option1 === selectedOption1)
          .map((item) => item.option2);
        option2Values.forEach((option2Value) => {
          let option = document.createElement("option");
          option.value = option2Value as string;
          option.text = option2Value as string;
          selectOption2.appendChild(option);
        });
      });

      // Add event listener for the second dropdown
      selectOption2.addEventListener("change", function () {
        let selectedOption1 = selectOption1.value;
        let selectedOption2 = selectOption2.value;

        // Find the object that matches the selected option1 and option2
        let selectedObject = productvariants.find(
          (item) => item.option1 === selectedOption1 && item.option2 === selectedOption2
        );

        if (selectedObject) {
          console.log("Selected Object:", selectedObject);
          // You can do whatever you want with the selectedObject here
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set("variant", `${selectedObject.id}`);
          window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
          sku.textContent = selectedObject.sku;
          handler();
          defaultVariant = false;
        }
      });
    }
    //dropdown ends here
  }
  const IsFemalePant = store.currentPage.product.tags.includes("Female")
    // Pants hamming handler
    // @ts-ignore
    if (productType === "pants" || (productType === "jackets" && !isBart)) {
      console.log("handler for addon");
      const addons = await getAddons(productId, productType ,IsFemalePant?"Female":"Male");
      let addonHeading = "";
      let isRequired = false;
      if (productType === "pants") {
        addonHeading = "Length is unfinished. Please select an inseam OR outseam";
        toolTipMsg = "Please select a Hemming!";
      }
      if (productType === "jackets" && !isBart) {
        addonHeading = "Select a rank from the dropdown";
        isRequired = true;
        toolTipMsg = "Please select a jacket rank!";
      }

      const addonsHtml = addons
        .map((a: Product, i: number) => {
          return `<div style="display: inline-block; width: 49%; padding-bottom: 10px;">
            <lable for="addon-${i}">${a.title}</lable>
            <span data-text="" id="custom_tooltip"></span>
            <select name="addon-${i}" id="addon-${i}" ${
            isRequired ? "required" : ""
          } style="margin-top: 5px;">
              <option></option>
              ${a.variants
                .map(
                  (v) =>
                    `<option value="${v.id}">${v.title} (<strong>${formatMoney(
                      v.price
                    )}</strong>)</option>`
                )
                .join()}
            </select></div>
          `;
        })
        .join();

      document.querySelector(".product-options")?.insertAdjacentHTML(
        "afterend",
        `<div class="addons">
         <h4 class="hamming-select-msg"><strong>${addonHeading}.</strong></h4>
        ${addonsHtml}  
        </div>`
      );
      // creating custom add to button  for products which have add on
      // class used for this button can be found in style-mods.scss in shopify

      //Hide other addon if select one 
      const selectIds: string[] = ['addon-0', 'addon-1', 'addon-2', 'addon-3'];

      // Function to reset select elements to default
      const resetSelects = (exceptId: string): void => {
        selectIds.forEach(id => {
          if (id !== exceptId) {
            const selectElement = document.getElementById(id) as HTMLSelectElement;
            if (selectElement) {
              selectElement.selectedIndex = 0;
            }
          }
        });
      };
    
      // Add event listeners to each select element
      selectIds.forEach(id => {
        const selectElement = document.getElementById(id) as HTMLSelectElement;
        if (selectElement) {
          selectElement.addEventListener('change', () => resetSelects(id));
        }
      });
    }

    //new productadding

    newAddtoCartBtn.addEventListener("click", async (e) => {
      e.preventDefault();
      const urlParams = new URLSearchParams(window.location.search);
      const quantity = Number((document.getElementById("qty") as HTMLInputElement)?.value);
      let lineItem = [{ id: Number(urlParams.get("variant")), quantity }];
      newAddtoCartBtn.classList.add("custom_loading_for_addon");
      newAddtoCartBtn.textContent = "";
      newAddtoCartBtn.disabled = true;

      if (defaultVariant && defaultVariant2 && productvariants.length>1) {
        newAddtoCartBtn.classList.remove("custom_loading_for_addon");
        newAddtoCartBtn.textContent = "Add To Cart";
        newAddtoCartBtn.disabled = false;
        newAddtoCartBtn.addEventListener("mouseover", () => {
          newAddtoCartBtn.setAttribute("title", "Please select a Variant!");
        });
        newAddtoCartBtn.addEventListener("mouseout", () => {
          newAddtoCartBtn.removeAttribute("title");
        });
        customTooltipVariant1?.classList.add("custom_tooltip");
        setTimeout(() => {
          customTooltipVariant1?.classList.remove("custom_tooltip");
        }, 3000);
      } else if (defaultVariant && !defaultVariant2&& productvariants.length>1) {
        newAddtoCartBtn.classList.remove("custom_loading_for_addon");
        newAddtoCartBtn.textContent = "Add To Cart";
        newAddtoCartBtn.disabled = false;
        newAddtoCartBtn.addEventListener("mouseover", () => {
          newAddtoCartBtn.setAttribute("title", "Please select a Variant!");
        });
        newAddtoCartBtn.addEventListener("mouseout", () => {
          newAddtoCartBtn.removeAttribute("title");
        });
        customTooltipVariant2?.classList.add("custom_tooltip");
        setTimeout(() => {
          customTooltipVariant2?.classList.remove("custom_tooltip");
        }, 3000);
      } else {
        const selectedHemmingVariantIds = await getAddonsData();

        if (
          selectedHemmingVariantIds.length == 0 &&
          (productType === "pants" || (productType === "jackets" && !isBart))
        ) {
          const customTooltip = document.getElementById("custom_tooltip");
          customTooltip?.setAttribute("data-text", `${toolTipMsg}`);
          customTooltip?.classList.add("custom_tooltip");
          newAddtoCartBtn.classList.remove("custom_loading_for_addon");
          newAddtoCartBtn.textContent = "Add To Cart";
          newAddtoCartBtn.disabled = false;
          newAddtoCartBtn.addEventListener("mouseover", () => {
            newAddtoCartBtn.setAttribute("title", `${toolTipMsg}`);
          });
          newAddtoCartBtn.addEventListener("mouseout", () => {
            newAddtoCartBtn.removeAttribute("title");
          });

          setTimeout(() => {
            customTooltip?.classList.remove("custom_tooltip");
          }, 800);
        } else {
          if (productType === "pants" || (productType === "jackets" && !isBart)) {
            lineItem = [{ id: Number(urlParams.get("variant")), quantity }].concat(
              selectedHemmingVariantIds.map((id) => ({ id, quantity }))
            );
          }
          console.log("lineItem", lineItem);
          addAddonShopifyCart(lineItem)
            .then(() => {
              getShopifyCart()
                .then((data) => {
                  sessionStorage.setItem("addonStatus", "added");
                  console.log("data", data);
                  const price = data.items.reduce((acc: number, item: ICartLineItem) => {
                    const variantPrice=getVariantPrice(item.variant_id)
                    console.log(variantPrice)
                    //@ts-ignore
                    if ((item.handle !== config.jacketRankings||variantPrice * item.quantity>0) && variantPrice!= undefined) {
                      console.log("getVariantPrice(item.variant_id) * item.quantity;",variantPrice * item.quantity)
                      return acc + variantPrice * item.quantity;
                    }
                    return acc;
                  }, 0);
                  const updatedCartData = data.items
                    .map((item: any, i: number) => {
                      const variantPrice = getVariantPrice(item.id);
                      return `
                <li class="shopify-mini-cart-item mini_cart_item"> 
              <a href="/cart/change?line=${
                i + 1
              }&amp;quantity=0" class="customremove" title="Remove" data-product_id=${
                        item.id
                      } data-product_sku="${item.sku}">x</a>
              <a href=${item.url} class="cart-item-image">
              <img width="100" height="130" src="${
                item.image
              }" class="attachment-shop_thumbnail size-shop_thumbnail wp-post-image" alt="" sizes="(max-width: 100px) 100vw, 100px">
              </a>
              <div class="cart-info">
              <span class="product-title">
                
            
             ${item.product_title}
                  
              </span>
              <span class="quantity">${
                item.quantity
              } × <span class="shopify-Price-amount amount">${formatMoney(variantPrice)}</span></span>
            </div>
            </li>
              `;
                    })
                    .join("");

                  console.log("updatedCartData", updatedCartData);

                  
                  document.querySelectorAll(".dropdown-cart .widget_shopping_cart_content").forEach(
                    (e) =>
                      (e.innerHTML = `
              <div class="widget_shopping_cart_content">
              <div class="shopping-cart-widget-body woodmart-scroll has-scrollbar">
                <div class="woodmart-scroll-content" tabindex="0" style="margin-right: -15px;">
                  <ul class="cart_list product_list_widget shopify-mini-cart">
    ${updatedCartData}
        </ul><!-- end product list -->
                </div>
              <div class="woodmart-scroll-pane" style="display: none;"><div class="woodmart-scroll-slider" style="height: 179px; transform: translate(0px, 0px);"></div></div></div><div class="shopping-cart-widget-footer">
                <p class="shopify-mini-cart__total total">
                  <strong>Subtotal:</strong> 
                  <span class="shopify-Price-amount amount">
                    <span class="" >${formatMoney(price)}</span>
                  </span>
                </p>
                <p class="shopify-mini-cart__buttons buttons">
                  <a href="/cart" class="button btn-cart wc-forward">View cart</a><button onclick="return window._SHOPY.checkout(event)" class="checkout-button checkout button alt wc-forward checkoutbtn" style="width: 100%; display: block;">Checkout</button>
                  <a href="/checkout" class="button checkout wc-forward">Checkout</a>
                </p>
              </div></div>
              `)
                  );

                  console.log("price", price);
                  console.log("data.price");
                  sessionStorage.setItem("spurit-global-multitabs.cart", JSON.stringify(data));
                  sessionStorage.setItem("addonStatus", "added");
                  newAddtoCartBtn.classList.remove("custom_loading_for_addon");
                  newAddtoCartBtn.textContent = "Add To Cart";
                  // document.querySelectorAll(".woodmart-shopping-cart").forEach((element) => {
                  //   element.classList.add("display-widget");
                  // });
                  //cartDiv.classList.add("display-widget")
                  newAddtoCartBtn.disabled = false;
                  // setTimeout(() => {
                  //   document.querySelectorAll(".woodmart-shopping-cart").forEach((element) => {
                  //     element.classList.remove("display-widget");
                  //   });
                  // }, 4000);
                  handler()
                  renderCIBalanceBanner();
                })
                .catch(() => {
                  alert("Somthing Went Wrong");
                  window.location.reload();
                });
            })
            .catch(() => {
              alert("The size you selected is unavailable. Please select a different size or item.");
              window.location.reload();
            });
        }

      }
    });
  });
}

const getAddonsData = async () => {
  let form: Array<number> = [];
  const addonsQty = 4;
  for (let i = 0; i < addonsQty; i++) {
    // @ts-ignore
    let addon = document.getElementById("addon-" + i)?.value;
    if (addon) {
      form.push(Number(addon));
    }
  }
  return form.filter(Boolean); 
};
 