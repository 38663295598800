import "./sentry";
import {
  onDomLoad,
  onChange,
  showGlobalProgress,
  redirectTo,
  formatMoney,
  checkoutBtn,
} from "./utils";
import { Store } from "./store/Store";
import { ICartLineItem } from "shopify-api-node";
import {
  createCheckoutV2,
  getShopifyCart,
  queryApi,
  remotePriceAccessor,
  updateShopifyCart,
} from "./store/actions";

import "./navigation";
import "./pages/payment.page";
import "./pages/checkout.page";
import "./pages/cart.page";
import "./pages/collection.page";
import "./pages/product.page";
import "./pages/compnay_issue_products.page";
import "./pages/login_register.page";
import "./scss/style.scss";
import { renderCIBalanceBanner } from "./pages/companyIssues";
import * as Sentry from "@sentry/browser";

// @ts-ignore
window._SHOPY ||= {} as any;

onDomLoad(async () => {
  try {
    const store = Store.getInstance();
    const productIds = new Set<number>();
    (store.currentPage.cart?.items || []).forEach((p) => productIds.add(p.product_id));
    document.querySelectorAll(".product").forEach((element) => {
      const id = Number(element.getAttribute("data-product-id"));
      id && productIds.add(id);
    });
    productIds.add(Number(store.currentPage.product?.id));

    queryApi(Array.from(productIds).filter(Boolean), store.currentPage.product?.id);
   
    await alterCartSection();
    // if visitor, hide prices
    if (!store.customer?.id) hidePrices();

    if (!store.client) {
      clearBannerForGuest();
    } else {
      await renderCIBalanceBanner();
    }
  } catch (e) {
    Sentry.captureException(e);
  }
});

const clearBannerForGuest = () => {
  document.querySelector(".shopy.balance-banner")?.remove();
};

const updateCartHandler = () => {
  const store = Store.getInstance();
  return async (event: Event) => {
    event.preventDefault();

    const lineItems = (store.currentPage.cart.items || []).map((item) => {
      const input = <HTMLInputElement>document.getElementById(`updates_${item.variant_id}`);
      return { id: item.variant_id, quantity: Number(input.value) };
    });
     
   const updatedCart= await updateShopifyCart(lineItems);
   if(updatedCart){ 
    window.location.reload();}
    window.location.href = "/cart";
    return false;
  };
};

// @ts-ignore
window._SHOPY.updateCart = updateCartHandler();

const alterCartSection = async () => {
  const addBtn = (element: Element) => {
    if (!element.querySelector(".checkoutbtn")) {
      element
        .querySelector(".shopify-mini-cart__buttons > a")
        ?.insertAdjacentHTML("afterend", checkoutBtn);
    }
  };
  const [getVariantPrice] = await remotePriceAccessor();

  const handler = async (base: Element) => {
    addBtn(base);
    document.querySelectorAll(".dropdown-cart .shopify-mini-cart-item").forEach((element) => {
      const variantId = Number(
        element.querySelector(".remove_from_cart_button")?.getAttribute("data-product_id")
      );

      const variantPrice = formatMoney(getVariantPrice(variantId));
      const e = element.querySelector(".shopify-Price-amount > .money");
      if (e && e.innerHTML != variantPrice) {
        e.innerHTML = variantPrice;
      }
    });

    const cart = await getShopifyCart();
    const price = cart.items.reduce((acc: number, item: ICartLineItem) => {
      return acc + getVariantPrice(item.variant_id) * item.quantity;
    }, 0);
    const formattedPrice = formatMoney(price);
    const ele = base.querySelector(".shopify-mini-cart__total.total  .money");
    if (ele && ele.innerHTML != formattedPrice) {
      ele.innerHTML = formattedPrice;
    }
    return renderCIBalanceBanner();
  };

  document
    .querySelectorAll(
      ".woodmart-shopping-cart.woodmart-cart-design-5.woodmart-cart-icon.woodmart-cart-alt"
    )
    .forEach((container) => {
      handler(container);
      onChange(container, () => handler(container));
    });
};

const checkoutHandler = async (event: Event) => {
  const normalCheckoutUrl = "/checkout";

  // Bypass custom checkout
  event.preventDefault();
  // @ts-ignore
  event.target.setAttribute("disabled", true);
  showGlobalProgress();

  const res = await getShopifyCart();
  if (res.items.length === 0) return redirectTo(normalCheckoutUrl);

  const checkout = await createCheckoutV2();
  return false;
};
// @ts-ignore
window._SHOPY.checkout = checkoutHandler;

const hidePrices = () => {
  try {
    const elements = Array.from(
      document.querySelectorAll(".dropdown-cart .shopify-mini-cart-item")
    );
    elements.push(document.querySelector(".wlm-content") as Element);

    elements
      .filter(Boolean)
      .forEach((ele) =>
        onChange(ele, () => document.querySelectorAll(".money").forEach((ele) => ele.remove()))
      );

    document.querySelectorAll(".single_add_to_cart_button").forEach((e) => e.remove());

    // Add Login to Buy button for non-logged in button
    const con = document.querySelector(".shopify-variation-add-to-cart");
    if (con) {
      const btn = document.createElement("a");
      btn.innerHTML = "Login to Buy";
      btn.setAttribute("href", "/pages/company-login");
      btn.setAttribute("class", "button");
      btn.style.backgroundColor = "#193976";
      btn.style.color = "#fff";
      con.appendChild(btn);
    }
  } catch (e) {
    console.error(e);
  }
};
