import { formatMoney, onDomLoad, setWithSelector, checkoutBtn, onChange } from "../utils";
import { Store } from "../store/Store";
import { getShopifyCart, remotePriceAccessor } from "../store/actions";
import { ICartLineItem } from "shopify-api-node";
import {
  companyIssueFullFilled,
  getCompanyIssueUsage,
  hasActiveCompanyIssues,
  hasMandatoryCompanyIssueItem,
  isCompanyIssueItemAddedToCart,
  renderCompanyIssueTable,
} from "./companyIssues";

const store = Store.getInstance();
const tag = store.client?.tag
if (store.currentPage.template === "cart") {
  const handler = async () => {
    const [getVariantPrice] = await remotePriceAccessor();
    const companyIssueUsage = await getCompanyIssueUsage();

    if(tag=='AAL'|| tag=='ENV'||tag=='AALEN'||tag=='AALJS'){
      const container = document.querySelector('.row.content-layout-wrapper')
      const viewOfferProductDiv=document.createElement('div')
      viewOfferProductDiv.classList.add('custom-promo-anchor-div')
      const newAnchor = document.createElement('a');
      newAnchor.classList.add('custom-promo-anchor')
      newAnchor.textContent = ' Go to 3-Piece Bundle Sale';
      newAnchor.setAttribute('href', `${store.url}/collections/3-piece-bundle`);
      viewOfferProductDiv.appendChild(newAnchor)
      container?.insertBefore(viewOfferProductDiv,container.firstChild)}

    const totalAmount = (store.currentPage.cart.items || []).reduce(
      (acc: number, item: ICartLineItem) => acc + getVariantPrice(item.variant_id) * item.quantity,
      0
    );
    document
      .querySelectorAll(".cart-totals-inner .sp-cart-original-total")
      .forEach((e) => (e.innerHTML = formatMoney(totalAmount)));

    (store.currentPage.cart.items || []).forEach(({ variant_id, quantity }: any) => {
      const variantPrice = getVariantPrice(variant_id);
      setWithSelector(`.sp-cart-item-price[data-key='${variant_id}']`, formatMoney(variantPrice));
      setWithSelector(
        `.sp-cart-item-line-price[data-key='${variant_id}']`,
        formatMoney(variantPrice * quantity)
      );
    });

    const container = document.querySelector(".wc-proceed-to-checkout");
    if (container && !container.querySelector(".checkoutbtn")) {
      container.insertAdjacentHTML("beforeend", checkoutBtn);
    }

    // Update cart btn
    const updateCartButtonHtml = `<div class="col-sm-6"><button class="button" onclick="return window._SHOPY.updateCart(event);" style="margin-bottom: 5px;"><span>Update Shopping Cart</span></button></div>`;

    const continueShopingCartButtonHtml = `<div class="col-sm-6" style="text-align: left;">
    <a href="${store.url}${store.client?.collection.storefrontPath}" class="button" style="margin-bottom: 5px;"><span >CONTINUE SHOPPING</span></a>
  </div>`;

    const cartActionsContainer = document.querySelector(".row.cart-actions");
    if (cartActionsContainer) {
      cartActionsContainer.querySelectorAll("div")[1].remove();
      cartActionsContainer.insertAdjacentHTML("beforeend", updateCartButtonHtml);
      cartActionsContainer.querySelectorAll("div")[0].remove();
      cartActionsContainer.insertAdjacentHTML("afterbegin", continueShopingCartButtonHtml);
    }

    if (
      hasMandatoryCompanyIssueItem() ||
      isCompanyIssueItemAddedToCart(companyIssueUsage.issueUsage)
    ) {
      if (!companyIssueFullFilled(companyIssueUsage)) {
        await renderCompanyIssueTable();
      }
    }

    if (hasActiveCompanyIssues()) {
    }
    const watchElement = document.querySelector(".shopify-cart");
    // console.log({ watchElement });
    if (watchElement) {
      // onChange(watchElement, async () => {
      // console.log("resetting after on change");
      // await handler();
      // });
    }
  };
  onDomLoad(handler);
}
