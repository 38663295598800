import { onDomLoad, redirectTo } from "./utils";
import { Store } from "./store/Store";
import { logOut, removeShopifyCart, updateCustomerPurchaseStatusMetafield, updateCustomerSessionMetafield } from "./store/actions";

const guestIdentifier = "/gue_";
const legacyGuestIdentifier = "/guest_";
const newNavGuestIdentifier = "/guest";

const store = Store.getInstance();

//@ts-ignore
console.log("parced value ",JSON.parse(store.purchase_status),"test",store?.purchase_status);

//@ts-ignore
if(JSON.parse(store.purchase_status)==null){
  updateCustomerPurchaseStatusMetafield('not purchased',store.customer.id)
    console.log('cache cleared & updates Value');
}


if(store?.session_status){
  //@ts-ignore
if(JSON.parse(store?.session_status).status=='logout'){
  //@ts-ignore
  console.log('store.session_status.status==',JSON.parse(store.session_status),".status is true")
  updateCustomerSessionMetafield(store.customer.id)
  window.location.replace('https://mandhuniforms-com.myshopify.com/account/logout')
  
}}

//@ts-ignore
if(store?.purchase_status){
  //@ts-ignore
  if(JSON.parse(store.purchase_status).status==='purchased'){
    console.log('purchased');
      removeShopifyCart()
      updateCustomerPurchaseStatusMetafield('not purchased',store.customer.id)
      window.location.reload()
    
  }}
if (
  store.currentPage.template === "list-collections" ||
  store.currentPage.path.endsWith("collections/all")
) {
  
  const handle = !store.customer?.id
    ? store.webGuestCollection.handle
    : !store.client
    ? store.visitorCollection.handle
    : store.client.collection.handle;
  redirectTo(`/collections/${handle}`);
}
onDomLoad(() => {
  
  if (store.client) {   
    
    const links = document.querySelectorAll("a");
    // const replacement = store.client.tag.toLowerCase();
    links.forEach((link) => {
      const href = link.getAttribute("href");
      // TODO: search with regex
      // const replacement = store.currentPage.path.split('/')[2];
      let replacement = store.client?.collection.handle;

      //legacy logic
      if (href?.includes("/collections/gue_") || href?.includes("/collections/guest_")) {
        replacement = store.client?.tag.toLowerCase();
        const replacementHref = href
          .replace(guestIdentifier, `/${replacement}_`)
          .replace(legacyGuestIdentifier, `/${replacement}_`);
        link.setAttribute("href", replacementHref);
      } else if (href?.includes("/collections/guest")) {
        const replacementHref = href
          .replace(newNavGuestIdentifier, `/${replacement}`)
        link.setAttribute("href", replacementHref);
      }
    });
  }
});