import { onDomLoad, onChange, formatMoney } from "../utils";
import { Store } from "../store/Store";
import { remotePriceAccessor } from "../store/actions";

const store = Store.getInstance();
if (store.currentPage.template === "collection") {
  onDomLoad(async () => {
    const [getVariantPrice] = await remotePriceAccessor();

    const alterPrices = () => {
      document.querySelectorAll(".product").forEach((element) => {
        const variantId = Number(element.getAttribute("data-id"));
        if (!variantId) return;
        const variantPrice = getVariantPrice(variantId);
        const moneyElement = element.querySelector(".money");
        if (moneyElement) {
          moneyElement.innerHTML = formatMoney(variantPrice);
        }
      });
    };
    alterPrices();
    const grid = document.querySelector(".wlm-content");
    if (grid) {
      onChange(grid, alterPrices);
    }
  });
}
