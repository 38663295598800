import { Store } from "./Store";
import { Checkout, Customer, PaymentDetail } from "../interfaces";
import axios, { AxiosResponse } from "axios";
import { PaymentGateway } from "../enums/PaymentGateway";
import { AccountType } from "../enums/AccountType";
import { Product } from "../interfaces/Product";
import { Variant } from "../interfaces/Variant";
import memoize from "lodash/memoize";
import * as Sentry from "@sentry/browser";
import Client, { CustomAttribute } from "shopify-buy";
import { redirectTo } from "../utils";
import { ICart, ICartLineItem ,ICheckoutLineItem} from "shopify-api-node";

const templateHeader = "X-TEMPLATE";
const urlHeader = "X-URL";
const hmacHeader = "X-HMAC";
const customerIdHeader = "X-CUSTOMER-ID";

const store = Store.getInstance();

const api = axios.create({
  baseURL: process.env.API,
  headers: {
    [templateHeader]: store.currentPage.template as string,
    [customerIdHeader]: store.customer?.id?.toString(),
    [urlHeader]: new URL(window.location.href).pathname as string,
    [hmacHeader]: store.currentPage.hmac as string,
  },
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response?.data?.action?.type === "REDIRECT") {
      window.location.href = error.response?.data?.action?.value;
    }
    return Promise.reject(error);
  }
);

const shopifyClient = Client.buildClient({
  domain: process.env.SHOP as string,
  storefrontAccessToken: process.env.SHOP_TOKEN as string,
});

const extractStandardResponseData = (res: AxiosResponse) => res.data.data;

export const getCheckout = memoize(async () => {
  const checkoutId = store.currentPage.path.split("/")[3];
  return (store.checkout = checkoutId.length
    ? ((await api.get(`/v1/checkouts/${checkoutId}`).then(extractStandardResponseData)) as Checkout)
    : undefined);
});

export const pullCheckout = (checkoutId: number) =>
  api
    .get(`/v1/checkouts/${checkoutId}/poll`)
    .then(extractStandardResponseData) as Promise<Checkout>;

export const getCheckoutFromOrder = async (): Promise<Checkout> => {
  return (store.checkout = await api
    .get(`/v1/orders/${store.currentPage.order.id}/checkout`)
    .then(extractStandardResponseData));
};

export const createPaymentDetails = async (
  totalDue: number,
  allotment: number,
  payroll: number,
  companyIssue = false
): Promise<PaymentDetail[]> => {
  const remaining = companyIssue ? 0 : Math.round(totalDue - allotment - payroll);
  const paymentDetails: Partial<PaymentDetail>[] = [];
  if (allotment) {
    paymentDetails.push({
      amount: Number(allotment),
      gateway: PaymentGateway.M_AND_H,
      accountType: AccountType.ALLOTMENT,
    });
  }
  if (payroll) {
    paymentDetails.push({
      amount: Number(payroll),
      gateway: PaymentGateway.M_AND_H,
      accountType: AccountType.PAYROLL,
    });
  }

  if (companyIssue) {
    paymentDetails.push({
      amount: totalDue,
      gateway: PaymentGateway.M_AND_H,
      accountType: AccountType.COMPANY_ISSUE,
    });
  }
  if (remaining) {
    paymentDetails.push({ amount: remaining, gateway: PaymentGateway.SHOPIFY });
  }

  return api
    .post(`/v1/checkouts/${store.checkout?.id}/payment_details`, {
      paymentDetails,
    })
    .then(extractStandardResponseData);
};

export const clearPaymentDetails = () => {
  const store = Store.getInstance();
  return api
    .delete(`/v1/checkouts/${store.checkout?.id}/payment_details`)
    .then(extractStandardResponseData);
};

let queryRequestStatus: "NOT-STARTED" | "IN-PROGRESS" | "SUCCESS" | "FAILURE" = "NOT-STARTED";
const products = new Map<number, Product>();
const variants = new Map<number, Variant>();

const variantPriceFunctionHandler = (id: number) => variants.get(id)?.price as number;

const remotePriceAccessorPromises: [
  resolve: (...arg: any[]) => unknown,
  reject: (...arg: any[]) => unknown
][] = [];

export const remotePriceAccessor = async (): Promise<
  [(id: number) => number, Map<number, Variant>, Map<number, Product>]
> => {
  if (queryRequestStatus === "IN-PROGRESS" || queryRequestStatus === "NOT-STARTED") {
    return new Promise((resolve, reject) => {
      remotePriceAccessorPromises.push([resolve, reject]);
    });
  } else if (queryRequestStatus === "SUCCESS") {
    return Promise.resolve([variantPriceFunctionHandler, variants, products]);
  } else {
    throw Error();
  }
};

export const queryApi = async (productIds: number[], productId?: number) => {
  try {
    queryRequestStatus = "IN-PROGRESS";
    productIds =
      Store.getInstance().currentPage.template === "collection"
        ? await getCollectionProductIds()
        : productIds;
    const response = productIds.length
      ? ((await api
          .post("/v1/query", { productIds, productId })
          .then(extractStandardResponseData)) as Product[])
      : [];
    response.forEach((product) => {
      products.set(product.id, product);
      product.variants.forEach((v) => variants.set(v.id, v));
    });

    queryRequestStatus = "SUCCESS";
    remotePriceAccessorPromises.forEach(([resolve]) =>
      resolve([variantPriceFunctionHandler, variants, products])
    );
  } catch (e) {
    console.error(e);
    queryRequestStatus = "FAILURE";
    remotePriceAccessorPromises.forEach(([, reject]) => reject(e));
    throw e;
  }
};

export const getCollectionProductIds = () => {
  return api.get("/v1/query/collection_products").then(extractStandardResponseData) as Promise<
    number[]
  >;
};

export const getAddons = (productId: number | string, productType: string ,tag:string) =>
  api.get("/v1/addons", { params: { productId, productType,tag } }).then(extractStandardResponseData);

// export const createCheckout = (checkout: any): Promise<{ url: string | undefined }> =>
//   api.post("/v1/checkouts", checkout.items).then(extractStandardResponseData);
// this is for checkout extensibility purposes
export const createCheckoutV2 = async () => {
  Sentry.addBreadcrumb({ message: "Creating checkout" });
  const cart = await getShopifyCart();
  if (cart.items.length === 0) return redirectTo("/cart");
console.log(cart.items)
  const customAttribute = cart.items.reduce(
    (acc: { [key: number]: CustomAttribute[] }, item: ICartLineItem) => {
      const properties = Object.keys(item.properties || {});
      acc[item.variant_id] = properties.map((key) => ({ key, value: item.properties[key] }));
      return acc;
    },
    {}
  );
  const idScope = "ProductVariant";
  // const lineItems = cart.items.map((li: any) => {
  //   const graphId = Buffer.from(`gid://shopify/${idScope}/${li.id}`, "utf-8").toString("base64");
  //   return {
  //     quantity: li.quantity,
  //     variantId: graphId,
  //     customAttributes: customAttribute[li.variantId],
  //   } as unknown as ICheckoutLineItem;
  // });
  const lineItems = cart.items.map((li: any) => {
    const graphId = `gid://shopify/${idScope}/${li.id}`
    return {
      quantity: li.quantity,
      merchandiseId: graphId,
      customAttributes: customAttribute[li.variantId],
    } as unknown as ICheckoutLineItem;
  });
//   const shippingAddress = await getCustomerDefaultAddress(store.customer.id)
// console.log("ShippingAddress",shippingAddress)
//   // @ts-ignore
//   const checkout = await shopifyClient.checkout.create({ lineItems,shippingAddress });
//   console.log("Checkout Created" , checkout);
  const newLineItems = cart.items.map((item:any) => ({
// @ts-ignore
    // @ts-ignore
    variant: { id: item.variant_id, product: { id: item.product_id,title:item.product_title
    },},
    quantity: item.quantity,
  }));

//   const chekoutDataForMiddleware = {
//     lineItems: newLineItems,
//     //@ts-ignore
//     webUrl: checkout?.webUrl,
//   };

//   Sentry.addBreadcrumb({ message: "Checkout Created", data: { cart, checkout } });

//   // Save checkout in middleware database
//    await api.post("/v1/checkouts_v2", chekoutDataForMiddleware).then(extractStandardResponseData);
  const checkoutv2Data = {
    line:lineItems,
    lineItems:newLineItems
  }

  await api.post("/v3/checkout/create", checkoutv2Data).then(extractStandardResponseData);
};



export const refreshCheckout = (token: string, step: string): Promise<Checkout> =>
  api.post(`/v1/checkouts/${token}/refresh`, { step }).then(extractStandardResponseData); 

export const updateShopifyCart = (lineItems: { id: number; quantity: number }[]) =>{
console.log("lineItems",lineItems)
  return axios.post("/cart/update.js", {
    updates:lineItems.reduce((acc: { [key: number]: number }, item) => {
    acc[item.id] = item.quantity;
    return acc;
    }, {}),
  }).then(extractStandardResponseData)
}

export const addAddonShopifyCart = (lineItems: { id: number; quantity: number }[]) =>{
  console.log("lineItems",lineItems)
    return axios.post("/cart/add.js", {
      items:lineItems
    }).then(extractStandardResponseData)
  }
  
export const getShopifyCart = () => axios.get("/cart.js").then((res) => res.data);

export const removeShopifyCart = () => axios.get("/cart/clear.js").then((res) => res.data);
export const logOut = () => axios.get("/account/logout").then((res) => res.data);

export const updateCustomerPurchaseStatusMetafield = (status:string,customerId:number) => api.post('/v2/checkouts_v2/add_purchase_status_metafield',{status:status,customerId:customerId}).then((res) => res.data);
export const updateCustomerSessionMetafield = (sessionStatus:string,customerId:number) => api.post('/v2/checkouts_v2/update_customer_session_metafield',{sessionStatus:sessionStatus,customerId:customerId}).then((res) => res.data);

export const applyDiscountCode = (
  checkoutId: number,
  code: string,
  step: string
): Promise<Checkout> => {
  return api
    .patch(`/v1/checkouts/${checkoutId}/promotions`, { code, step })
    .then(extractStandardResponseData);
};

export const clearDiscountCode = (
  checkoutId: number,
  code: string,
  step: string
): Promise<Checkout> => {
  return api
    .delete(`/v1/checkouts/${checkoutId}/promotions`, { params: { code, step } })
    .then(extractStandardResponseData);
};

export const getPaymentOptions = memoize((checkoutId: number) =>
  api.get(`/v1/checkouts/${checkoutId}/payment_options`).then(extractStandardResponseData)
);

export const getCompanyIssueUsage = (lineItems: { variantId: number; quantity: number }[]) =>
  api
    .get(`/v1/checkouts/company_issue_usage`, { params: lineItems })
    .then(extractStandardResponseData);

export const getCompanyIssueProducts = (): Promise<Product[]> =>
  api.get("/v1/customers/allowances/company_issue_products").then(extractStandardResponseData);


 